@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.about {
    padding: 1rem 0rem;
    border-bottom: 1px solid lightgrey;

    &__title {
        text-align: center;
        margin: 0rem;
        padding-bottom: 1rem;
    }

    &__info {
        text-align: left;

        &-name {
            color: darkblue;
            font-weight: 600;
            letter-spacing: 0.2rem;
        }

        &-text {
            color: darkblue;
            font-weight: 400;
            letter-spacing: 0.1rem;
        }

        & p {
            margin-bottom: 0.5rem;
        }
    }
}
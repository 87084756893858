@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home {
    padding: 1rem;
    text-align: center;
    background-image: url(../../assets/images/background-cog.webp);
    background-position: center;
    background-size: cover;

    @include tablet {
        padding: 1rem 2rem;
    }

    @include desktop {
        padding: 1rem 10rem;
    }

    &__nav {
        display: flex;
        flex-direction: column;
        padding: 0rem 0rem 1rem;
        border-bottom: 1px solid lightgrey;

        @include tablet {
            flex-direction: row;
            justify-content: center;
            gap: 2rem;
        }

        @include desktop {
            gap: 6rem;
        }

        & a {
             
            & h3 {
                color: grey;
                
                &:hover {
                    color: black;
                    text-transform: uppercase;
                }
            }

            &:link,
            &:visited {
                text-decoration: none;
                color: inherit;
            }
        }
    }
}
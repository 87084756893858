@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.projects {
    padding: 1rem 0rem 0rem;
    border-bottom: 1px solid lightgrey;

    &__title {
        text-align: center;
        margin: 0rem;
        padding-bottom: 1rem;
    }

    &__card {
        border-top: 1px dashed gray;
        padding: 1rem 0rem 1rem;

        & h2 {
            padding-bottom: 0.5rem;
        }   
    }

    &__info {
        text-align: center;

        &-title {
            color: darkred;
            font-weight: 600;
            letter-spacing: 0.1rem;
        }

        &-link {
            color: darkred;
            font-weight: 600;
            letter-spacing: 0.1rem;
            cursor: pointer;
        }

        & p {
            padding-bottom: 0.5rem;
            text-align: left;
        }
    }

    &__space {
        @include desktop {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }
    }

    &__media {
        display: flex;
        justify-content: center;
        width: 100%;

        @include desktop {
            width: 50%;
            justify-content: space-between;
            // gap: 0.25rem;
        }

        &-show {
            display: flex;
        }

        & iframe {
            width: 100%;
        }

        & img {
            width: 100%;
            border-radius: 0.25rem;
            cursor: pointer;
        }
    }

    &__tech {
        display: flex;
        justify-content: space-around;
        width: 100%;

        @include desktop {
            width: 50%;
            padding: 2rem;
        }

        &-icon {
            padding: 1rem 0.25rem;

            &:hover {
                animation: shake 0.5s infinite;
            }

            &.two , &.four {
                align-self: flex-end;
            }
        }

        & img {
            width: 3rem;

            @include tablet {
                width: 4rem;
            }

            @include desktop {
                width: 6rem;
            }
        }
    }
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
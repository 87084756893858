@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contacts {
    padding: 1rem 0rem;
    border-bottom: 1px solid lightgrey;

    &__title {
        text-align: center;
        margin: 0rem;
        padding-bottom: 1rem;
    }

    &__info {
        padding-bottom: 1rem;
    }

    &__logos {
        display: flex;
        justify-content: center;
        padding-bottom: 1rem;

        & img {
            width: 2rem;
            margin: 0rem 0.5rem;
            cursor: pointer;
        }
    }
}